import { SearchWidgetProps } from '@/interfaces/nopk-ui-kit/index'

import SearchField from './search-field'
import SearchResults from './search-results'

export const CustomerSearchWidget = ({
  searchQuery,
  searchResults,
  inputPlaceholder,
  onChangeCallback,
  clearSearch,
  loading,
}: SearchWidgetProps) => {
  return (
    <section className="w-2/3 mb-10">
      <div
        id="customer-search-widget"
        className={`relative w-full border border-gray-mediumLight rounded-md ${
          searchResults?.length ? 'shadow-lg border' : 'shadow-none'
        }`}
        data-component="customer-search-widget"
      >
        <SearchField
          searchQuery={searchQuery}
          inputPlaceholder={inputPlaceholder}
          onChangeCallback={onChangeCallback}
          clearSearch={clearSearch}
          hasResults={!!searchResults?.length}
        />
        <SearchResults
          searchCharacterCount={searchQuery.length}
          searchResults={searchResults}
          loading={loading}
        />
      </div>
    </section>
  )
}
