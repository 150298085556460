import Link from 'next/link'
import React from 'react'

interface SearchResultRowProps {
  name: string
  id: string
  hasTariff?: boolean
}

const SearchResultRow: React.FC<SearchResultRowProps> = ({
  hasTariff,
  name,
  id,
}) => {
  return (
    <Link href={`/customers/${id}`}>
      <li
        id={`search-result-${id}`}
        className={`search-result-row flex cursor-pointer items-center justify-between transition-all duration-100 ease-in-out h-10 mx-auto rounded-md px-3 w-[97%] py-2 my-2 group ${
          hasTariff && 'hover:bg-green-mediumLight'
        }`}
      >
        <h3
          className={`flex-1 transition-all duration-100 ease-in-out ${
            hasTariff ? 'group-hover:text-white' : 'text-gray-mediumLight'
          } font-regular`}
        >
          {name}
        </h3>
        <p
          className={`text-sm  ${
            hasTariff ? 'group-hover:text-white' : 'text-gray-mediumLight'
          } mt-[5px] transition-all duration-100 ease-in-out`}
        >
          ID: {id}
        </p>
      </li>
    </Link>
  )
}

export default SearchResultRow
