import type { NextPage } from 'next'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

import { Layout } from '@/components/layout'
import { GridColumn, GridContainer } from '@/components/nopk-ui-kit/grid'
import { CustomerSearchWidget } from '@/components/nopk-ui-kit/search-widgets/customer-search-widget'
import { BodyTwo, DisplayLevelFour } from '@/components/nopk-ui-kit/typography'
import { CustomerSearchResultProps } from '@/interfaces/nopk-ui-kit/index'
import { customersSearch } from '@/utils/internal-api-helpers'

const Home: NextPage = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchResults, setSearchResults] = useState<
    CustomerSearchResultProps[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const searchCustomers = async () => {
      setLoading(true)
      const customerResults = await customersSearch(searchQuery)
      setSearchResults(customerResults)
      setLoading(false)
    }

    if (searchQuery.length > 2) {
      searchCustomers()
    } else {
      setSearchResults([])
    }
  }, [searchQuery])

  const clearSearch = () => setSearchQuery('')

  return (
    <Layout title="Search Customers">
      <GridContainer customStyles="md:w-2/3 mx-auto relative left-8">
        <GridColumn>
          <div className="mb-4">
            <DisplayLevelFour>
              <h1>Customer Agreement Tool</h1>
            </DisplayLevelFour>
            <BodyTwo>
              <p className="mt-5 text-base max-w-[830px]">
                Please search for a current customer or create a new one. In
                order to create an agreement for a customer. You must first make
                sure they have been added to the system.{' '}
                <Link href="https://sparkly-power-46e.notion.site/Customer-Authentication-Tool-C-A-T-deb6c0d116a64327a8e810c550c97aec#18edbfb19af54a098a9e3670e21864a5">
                  <a
                    className="font-medium cursor-pointer text-blue-mediumLight hover:underline whitespace-nowrap"
                    target="_blank"
                  >
                    Learn More
                  </a>
                </Link>
              </p>
            </BodyTwo>
          </div>

          <CustomerSearchWidget
            inputPlaceholder="Search using customer name"
            searchQuery={searchQuery}
            onChangeCallback={setSearchQuery}
            searchResults={searchResults}
            clearSearch={clearSearch}
            loading={loading}
          />
        </GridColumn>
      </GridContainer>
    </Layout>
  )
}

export default Home
