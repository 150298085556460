import React from 'react'

import { CustomerSearchResultProps } from '@/interfaces/nopk-ui-kit'

import SearchResultRow from './search-result-row'

interface SearchResultsProps {
  searchResults: CustomerSearchResultProps[]
  searchCharacterCount: number
  loading?: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({
  searchCharacterCount,
  searchResults,
  loading,
}) => {
  if (searchCharacterCount < 3) return <></>
  const renderNoResults = !loading && !searchResults?.length
  return (
    <ol
      id="search-results-container"
      className="w-full overflow-hidden bg-white rounded-b search-results-container"
    >
      {searchResults?.map((result) => (
        <SearchResultRow
          name={result.name}
          id={result.accountNumber}
          hasTariff={!!result.formattedEffectiveTariff}
          key={`SearchResults_${result.accountNumber}`}
        />
      ))}

      {renderNoResults ? (
        <li className="w-full p-4 text-center text-gray-500">
          No results found
        </li>
      ) : loading ? (
        <li className="w-full p-4 text-center text-gray-500">Loading...</li>
      ) : null}
    </ol>
  )
}

export default SearchResults
